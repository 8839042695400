import React from "react";

import { withStyles } from "@material-ui/styles";

import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const fieldTypes = [
    {
        name: "First Name",
        id: "first_name"
    },
    {
        name: "Last Name",
        id: "last_name"
    },
    {
        name: "Full Name",
        id: "full_name"
    },
    {
        name: "Email",
        id: "email"
    },
    {
        name: "Phone",
        id: "phone_number"
    }
];

class FormFieldsEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.value || [],
            open: Object.keys(this.props.value).length > 0
        }
    }

    componentDidUpdate(prev){
        if(this.props.value !== prev.value){
            this.setState({fields:this.props.value});
        }
    }

    render() {
        return (
            <Grid container spacing={3} className="widget_inner_form">
                <Grid item xs={12}>
                    <h3 className={!this.state.open ? 'closed' : ''}>
                        {this.state.open ?
                            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ open: !this.state.open })} /> :
                            <ChevronRightIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ open: !this.state.open })} />
                        }
                        Form Fields
                        <button
                            style={{ marginLeft: 'auto' }}
                            onClick={() => this.addNewField()}
                            className="btn white"><AddBoxIcon />Add New Field
                        </button>
                    </h3>
                </Grid>

                {this.state.open && this.state.fields.map((formField, idx) => {
                    return this.renderField(formField, idx)
                })}
            </Grid>
        );
    }

    renderField(field, idx) {
        const inputStyle = { width: '100%' };
        return (
            <Grid item xs={12} container spacing={1} key={'form-field' + idx} style={{ alignItems: 'center', paddingBottom: 15, marginBottom: 15, borderBottom: '1px solid #ececec' }}>
                <Grid item xs={6}>
                    <InputLabel>Label</InputLabel>
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={field.label}
                        onChange={(e) => this.updateLabel(field, e.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>Description</InputLabel>
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        style={inputStyle} value={field.description}
                        onChange={(e) => this.updateDescription(field, e.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>Field Type</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={field.type.id}
                        onChange={(e) => this.updateType(field, e.target.value)}
                    >
                        {fieldTypes.map(type => (
                            <MenuItem key={'field-type-' + type.id} value={type.id}>{type.name}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel style={{ marginTop: '10px' }}
                        control={
                            <Checkbox checked={field.required}
                                onChange={() => this.updateFieldRequired(field)}
                                color="primary" />
                        }
                        label="Required"
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={1}>
                    <DeleteIcon onClick={() => this.deleteField(field)} />
                </Grid>
            </Grid>
        );
    }

    addNewField() {
        this.setState({
            ...this.state,
            open: true,
            fields: this.state.fields.concat({ type: fieldTypes[0], label: '', description: '', required: false })
        }, () => this.props.onFieldsChange(this.state.fields));
    }

    updateType(field, value) {
        const type = fieldTypes.find(type => type.id === value);
        this.updateField(field, formField => ({ ...formField, type: type }));
    }

    updateLabel(field, value) {
        this.updateField(field, formField => ({ ...formField, label: value }));
    }

    updateDescription(field, value) {
        this.updateField(field, formField => ({ ...formField, description: value }));
    }

    updateFieldRequired(field) {
        this.updateField(field, formField => ({ ...formField, required: !formField.required }));
    }

    deleteField(field) {
        this.setState({
            ...this.state,
            fields: this.state.fields.filter(formField => formField !== field)
        }, () => this.props.onFieldsChange(this.state.fields));
    }

    updateField(field, transform) {
        this.setState({
            ...this.state,
            fields: this.state.fields.map(formField => {
                return formField !== field ? formField : transform(formField)
            })
        }, () => this.props.onFieldsChange(this.state.fields));
    }
}

const styles = {
    root: {
        flexGrow: 1,
    }
};

export default withStyles(styles)(FormFieldsEditor);
import React from "react";
import axios from "axios";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
//import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '../Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinkIcon from '@material-ui/icons/Link';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import WebinarDateAsset from "./WebinarDateAsset";
import RegisterWidget from "./RegisterWidget";

import FormFieldsEditor from "./FormFieldsEditor";
import FormStyleEditor from "./FormStyleEditor";
import WidgetCodes from './WidgetCodes';

import UrlParams from './UrlParams';


import { ico1, ico2, ico3, ico4 } from './icons';

import moment from 'moment';

export default class FormDesigner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            offers: [],
            clients: [],
            //webinars: [],
            ...this.initWidget()
        }

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleURLParamsChange = this.handleURLParamsChange.bind(this);
        this.generateCode = this.generateCode.bind(this);
        this.changeType = this.changeType.bind(this);
    }
    initWidget() {
        return {
            fields: [],
            styles: {},
            widgetData: {
                type: 'dynamic-webinar',
                name: '',
                description: '',
                client_id: undefined,
                offer_id: undefined,
                pattern: '',
                url_params: {},
                media_buys: [],
                landing_pages: []
            },
            errors: []
        }
    }

    async componentDidMount() {
        const { offers } = await this.getOffers();
        const { clients } = await this.getClients();
        await this.getG2WAccounts();

        if (this.props.widgetid) {
            const { data } = await axios.get(
                process.env.SERVER_ENDPOINT + `/widgets?id=${this.props.widgetid}`
            );

            if (data.data && data.data.length > 0) {

                const item = data.data[0];
                const resp = await this.getMediaBuys(item.offer_id);
                const res = await this.getLandingPages(item.offer_id);

                // let webinars = [];
                // if (item.type === 'odw-webinar') {
                //     webinars = (await this.getWebinars()).webinars;
                // }

                this.setState({
                    loading: false,
                    fields: item.fields ? JSON.parse(item.fields) : [],
                    styles: item.styles ? JSON.parse(item.styles) : {},
                    widgetData: {
                        widget_id: this.props.widgetid,
                        type: item.type,
                        name: item.name,
                        description: item.description,
                        client_id: item.client_id,
                        offer_id: item.offer_id,
                        //keyword: item.keyword,
                        pattern: item.pattern ? item.pattern.replace('EV', '') : '',
                        url_params: { client_id: item.client_id, ...(item.url_params ? JSON.parse(item.url_params) : {}) },
                        media_buys: item.media_buys ? JSON.parse('[' + item.media_buys + ']') : [],
                        landing_pages: item.landing_pages ? JSON.parse('[' + item.landing_pages + ']') : []
                    },
                    media_buys: resp.data,
                    landing_pages: res.data,
                    offers,
                    clients,
                    // webinars,
                    everWebinar: item.pattern ? item.pattern.includes('EV') : false,
                    showCodes: item.fields && item.styles
                })
            } else {
                this.setState({ offers, clients, loading: false })
            }
        } else {
            this.setState({ offers, clients, loading: false })
        }
    }
    handleChange(name) {
        return (e) => {
            const val = e.target.value;
            const data = { ...this.state.widgetData, [name]: val };
            if (name === 'offer_id') {
                data.media_buys = [];
                data.landing_pages = [];
                this.getMediaBuys(val).then(resp => this.setState({ media_buys: resp.data }));
                this.getLandingPages(val).then(resp => this.setState({ landing_pages: resp.data }))
            }
            if (name === 'client_id') {
                data.url_params['client_id'] = val;
            }
            this.setState({ widgetData: data, showCodes: false });
        }
    }
    handleMultiChange(name) {
        return (e, val) => {
            const data = { ...this.state.widgetData, [name]: val };
            this.setState({ widgetData: data });
        }
    }
    handleURLParamsChange(value, name) {
        const form = { ...this.state.widgetData }
        form.url_params[name] = value;

        this.setState({ widgetData: form, showCodes: false });
    }

    changeType(type) {
        return e => {
            if (this.state.widgetData.widget_id) {
                return
            }
            const data = this.initWidget();
            data.widgetData.type = type;
            this.setState({ ...data, showCode: false });
        }
    }

    async getClients() {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/clients")
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during clients fetch!"] })
        }
    }
    async getG2WAccounts() {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT +"/g2wAccounts")
            console.log('g2w', data)
            this.setState({
                g2wAccounts: data.g2w_api.map(e => ({
                    client_id: e.client_id,
                    client_name: e.gtw_account_name
                }))
            })
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during g2wAccounts fetch!"] })
        }
    }
    async getOffers() {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/offers")
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during offers fetch!"] })
        }
    }
    async getMediaBuys(offer_id) {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/mediaBuys?offers%5B0%5D=" + offer_id)
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during media_buys fetch!"] })
            return [];
        }
    }
    async getLandingPages(offer_id) {
        try {
            const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/landing-pages?offer=" + offer_id)
            return data;
        } catch (error) {
            this.setState({ errors: [...this.state.errors, "Error occurred during landing_pages fetch!"] })
            return [];
        }
    }

    generateCode() {
        const fields = this.state.fields;
        const styles = this.state.styles;
        const pattern = this.state.widgetData.pattern;
        const client = this.state.widgetData.client_id;
        const type = this.state.widgetData.type;
        const url_params = this.state.widgetData.url_params;

        if (!client) {
            return this.setState({ error: 'client' });
        }
        if (type === 'dynamic-webinar' && pattern === '') {
            return this.setState({ error: 'pattern' });
        }
        if (type === 'live-webinar' || type === 'odw-webinar' || type === 'lead-capture') {
            if (fields.length === 0) {
                return this.setState({ error: 'fields-required' });
            }
            if (Object.keys(styles).length === 0) {
                return this.setState({ error: 'styles-required' });
            }
            if (!url_params.destination_url || url_params.destination_url === '') {
                return this.setState({ error: 'destination_url' });
            }
        }
        if (type === 'odw-webinar') {
            if (!url_params.webinar_id || url_params.webinar_id === '') {
                return this.setState({ error: 'webinar_id' });
            }
            if (!url_params.destination_url || url_params.destination_url === '') {
                return this.setState({ error: 'destination_url' });
            }
        }

        this.setState({ showCodes: true, error: '' });
    }

    save() {
        const form = { ...this.state.widgetData };
        if (form.name === '') {
            return this.setState({ error: 'name' });
        }
        if (form.client_id === '') {
            return this.setState({ error: 'client' });
        }
        if (form.offer_id === '') {
            return this.setState({ error: 'offer' });
        }
        if (form.type === 'odw-webinar' && form.url_params.webinar_id === '') {
            return this.setState({ error: 'webinar_id' });
        }
        if (['live-webinar', 'odw-webinar', 'lead-capture'].includes(form.type)) {
            if (!this.state.fields || this.state.fields.length == 0) {
                return this.setState({ error: 'fields-required' });
            }
            if (!this.state.styles || Object.keys(this.state.styles).length == 0) {
                return this.setState({ error: 'styles-required' });
            }
        }

        if (form.type === 'dynamic-webinar') {
            if (form.pattern === '') {
                return this.setState({ error: 'pattern' });
            }
            form.pattern += this.state.everWebinar ? 'EV' : '';
        }

        const styles = Object.keys(this.state.styles).length > 0 ? { styles: JSON.stringify(this.state.styles) } : {};
        const fields = this.state.fields.length > 0 ? { fields: JSON.stringify(this.state.fields) } : {};

        delete form.url_params.client_id;
        const url_params = JSON.stringify(form.url_params);

        const widget = {
            ...form,
            ...styles,
            ...fields,
            url_params
        }
        axios.post(process.env.SERVER_ENDPOINT + "/widgets", widget).then(e => window.location.href = "/media-planner/form-builder").catch(e => console.log(e));
    }

    cancel(e) {
        e.preventDefault();
        return window.location.href = "/media-planner/form-builder";
    }

    render() {
        const form = this.state.widgetData;
        const type = form.type;
        const error = this.state.error;

        const offers = form.client_id ? this.state.offers.filter(o => o.client_id === form.client_id) : this.state.offers;

        //const media_buys_to_select = type ? this.state.media_buys.filter(m => types[this.props.type].includes(m.url_type)) : [];
        const media_buys_to_select = this.state.media_buys || [];
        const landing_pages = this.state.landing_pages || [];

        const isLive = type === 'live-webinar' || (type === 'dynamic-webinar' && !this.state.everWebinar);
        
        const clientsToSelect = (isLive ? this.state.g2wAccounts : this.state.clients) ||[];
        const client = clientsToSelect.find(c => c.client_id === this.state.widgetData.client_id);

        return this.state.loading ?
            <div style={{ width: '100%', height: '300px', display: 'flex' }}>
                <CircularProgress style={{ margin: '130px auto 0' }} />
            </div> :
            <Grid container spacing={0} className={"widget_container " + (form.widget_id ? 'edit' : '')}>
                {!form.widget_id ?
                    <>
                        <Grid item xs={3}
                            className={"widget_tab " + (type === "dynamic-webinar" ? "selected" : "")}
                            onClick={this.changeType("dynamic-webinar")}
                        >
                            {ico1(type === "dynamic-webinar" ? '#1592CD' : '#9B9B9B')}
                            <span>Dynamic Webinar Date & Time Widget</span>
                        </Grid>
                        <Grid item xs={3}
                            className={"widget_tab " + (type === "lead-capture" ? "selected" : "")}
                            onClick={this.changeType("lead-capture")}
                        >
                            {ico2(type === "lead-capture" ? '#1592CD' : '#9B9B9B')}
                            <span>Lead Capture Form</span>
                        </Grid>
                        <Grid item xs={3}
                            className={"widget_tab " + (type === "live-webinar" ? "selected" : "")}
                            onClick={this.changeType("live-webinar")}
                        >
                            {ico3(type === "live-webinar" ? '#1592CD' : '#9B9B9B')}
                            <span>Live Webinar Registration/Redirect Form</span>
                        </Grid>
                        <Grid item xs={3}
                            className={"widget_tab " + (type === "odw-webinar" ? "selected" : "")}
                            onClick={this.changeType("odw-webinar")}
                        >
                            {ico4(type === "odw-webinar" ? '#1592CD' : '#9B9B9B')}
                            <span>ODW Webinar Registration/Redirect Form</span>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={3}
                            className="widget_tab selected"
                        >
                            {type === "dynamic-webinar" ? <>{ico1('#1592CD')}<span>Dynamic Webinar Date & Time Widget</span></> :
                                type === "lead-capture" ? <>{ico1('#1592CD')}<span>Lead Capture Form</span></> :
                                    type === "live-webinar" ? <>{ico1('#1592CD')}<span>Live Webinar Registration/Redirect Form</span></> :
                                        type === "odw-webinar" ? <>{ico1('#1592CD')}<span>ODW Webinar Registration/Redirect Form</span></> :
                                            null}
                        </Grid>
                        <Grid item xs={9} className="widget_tab "></Grid>
                    </>}

                {type !== undefined && <>
                    <Grid item xs={12} container spacing={6} className="widget_header">
                        <Grid item xs={3}>
                            <InputLabel>Form / Widget Name *</InputLabel>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={form.name}
                                onChange={this.handleChange('name')}
                            />
                            {error && error === 'name' && <p className="error">Name is required</p>}
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Form / Widget Description</InputLabel>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                multiline
                                fullWidth
                                rowsMax="4"
                                value={form.description}
                                onChange={this.handleChange('description')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>{isLive ? 'GoToWebinar Account *': 'Client *' }</InputLabel>
                            <Select
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                value={form.client_id || ''}
                                onChange={this.handleChange('client_id')} style={{ width: '100%' }}>
                                {clientsToSelect.map((client, idx) => (
                                    <MenuItem value={client.client_id} key={'client_' + idx}>{client.client_name}</MenuItem>
                                ))}
                            </Select>
                            {error && error === 'client' && <p className="error">Client is required</p>}
                        </Grid>
                        <Grid item xs={3}>
                            {form.client_id ? <>
                                <InputLabel>Offer *</InputLabel>
                                <Select
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    value={form.offer_id || ''}
                                    onChange={this.handleChange('offer_id')}>
                                    {offers.map((offer, idx) => (
                                        <MenuItem value={offer.offer_id} key={'offer_' + idx}>{offer.offer_name}</MenuItem>
                                    ))}
                                </Select>
                                {error && error === 'offer' && <p className="error">Offer is required</p>}
                            </> : null
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={0} className="widget_form">
                        <UrlParams url_params={form.url_params} onChange={this.handleURLParamsChange} client={client} type={form.type}></UrlParams>
                        {type === 'dynamic-webinar' &&
                            <>
                                <Grid item xs={4}>
                                    <InputLabel>Date format</InputLabel>
                                    <Select
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={form.pattern}
                                        onChange={this.handleChange('pattern')}
                                    >
                                        <MenuItem value="dddd, MMMM D, YYYY @ h:mm A">{moment().format('dddd, MMMM D, YYYY @ h:mm A')}</MenuItem>
                                        <MenuItem value="dddd, MMMM Do @ h:mm A">{moment().format('dddd, MMMM Do @ h:mm A')}</MenuItem>
                                        <MenuItem value="dddd, MMMM Do @ h A">{moment().format('dddd, MMMM Do @ h A')}</MenuItem>
                                        <MenuItem value="MMMM D, YYYY @ h:mm A">{moment().format('MMMM D, YYYY @ h:mm A')}</MenuItem>
                                        <MenuItem value="MMMM D, YYYY @ h A">{moment().format('MMMM D, YYYY @ h A')}</MenuItem>
                                        <MenuItem value="MMMM Do @ h:mm A">{moment().format('MMMM Do @ h:mm A')}</MenuItem>
                                        <MenuItem value="MMMM Do @ h A">{moment().format('MMMM Do @ h A')}</MenuItem>
                                    </Select>
                                    {error && error === 'pattern' && <p className="error">Date format is required</p>}
                                    <p style={{ fontSize: '12px', color: '#747374' }}>The details about the date formats can be found on &nbsp;
                                        <a href="https://devhints.io/datetime#momentjs-format">https://devhints.io/datetime</a>
                                    </p>
                                </Grid>
                                <Grid item xs={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <InputLabel>EverWebinar?</InputLabel>
                                    <Checkbox checked={this.state.everWebinar}
                                        onChange={(e) => { this.setState({ everWebinar: e.target.checked }) }}
                                        color="primary" />
                                </Grid>
                            </>}


                        <Grid item xs={12}></Grid>
                        {form.offer_id && landing_pages.length > 0 && <>
                            <Grid item xs={4}>
                                <InputLabel><LinkIcon /> Linked Landing Pages</InputLabel>
                                <Autocomplete
                                    multiple
                                    value={form.landing_pages}
                                    options={landing_pages.map(l => l.landing_page_id)}
                                    getOptionLabel={(option) => {
                                        const lp = landing_pages.find(l => option === l.landing_page_id);
                                        if (lp) {
                                            return lp.name_internal
                                        } else {
                                            return null
                                        }
                                    }}
                                    disableCloseOnSelect
                                    filterSelectedOptions
                                    onChange={this.handleMultiChange('landing_pages')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        </>}

                        {form.offer_id && media_buys_to_select.length > 0 &&
                            <Grid item xs={4}>
                                <InputLabel><LinkIcon /> Linked Media buys</InputLabel>
                                <Autocomplete
                                    multiple
                                    value={form.media_buys}
                                    options={media_buys_to_select.map(m => m.media_buy_id)}
                                    getOptionLabel={(option) => {
                                        const mb = media_buys_to_select.find(m => option === m.media_buy_id);
                                        return mb ? mb.media_buy_id + ' - ' + mb.media_buy_name : option
                                    }}
                                    disableCloseOnSelect
                                    filterSelectedOptions
                                    onChange={this.handleMultiChange('media_buys')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}></Grid>

                        {(type === 'live-webinar' || type === 'odw-webinar' || type === 'lead-capture') &&
                            <>
                                <Grid item xs={12} lg={4}>
                                    <FormFieldsEditor value={this.state.fields} onFieldsChange={(fields) => this.setState({ fields })} />
                                    {error && error === 'fields-required' && <p className="error">Fields are required</p>}
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <FormStyleEditor value={this.state.styles} onStyleChanged={(styles) => this.setState({ styles })} onRender={(style) => this.setState({ style })} />
                                    {error && error === 'styles-required' && <p className="error">Styles are required</p>}
                                </Grid>
                            </>
                        }

                        <Grid item xs={12}>
                            <Grid container spacing={3} className="widget_inner_form">
                                <Grid item xs={12}>
                                    <h3 className={!this.state.showCodes ? 'closed' : ''}>
                                        {typeof this.state.showCodes !== 'undefined' ?
                                            this.state.showCodes ?
                                                <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ showCodes: !this.state.showCodes })} /> :
                                                <ChevronRightIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ showCodes: !this.state.showCodes })} /> :
                                            null
                                        }
                                        Code Snippets
                                        <button
                                            disabled={this.state.showCodes}
                                            style={{ marginLeft: 'auto' }}
                                            onClick={() => this.generateCode()}
                                            className="btn white">Generate</button>
                                    </h3>
                                </Grid>
                                {error && error === 'webinar_id' && <p className="error" style={{ marginTop: 15 }}>Webinar ID is required</p>}
                                {error && error === 'destination_url' && <p className="error" style={{ marginTop: 15 }}>Alternate URL is required</p>}
                                {this.state.showCodes && <WidgetCodes
                                    fields={this.state.fields}
                                    styles={this.state.styles}
                                    client={client}
                                    widget={form}
                                    everWebinar={this.state.everWebinar}
                                />}
                            </Grid>
                        </Grid>


                        <Grid item xs={12} className="widget_actions">
                            <button
                                style={{ marginLeft: 'auto' }}
                                onClick={this.cancel}
                                className="btn white">Cancel</button>
                            <button
                                onClick={this.save}
                                style={{ marginLeft: '10px' }}
                                className="btn">Save</button>
                        </Grid>
                    </Grid>
                </>
                }
            </Grid>

    }

}
import React from "react";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";

import WebinarDateAsset from "./WebinarDateAsset";
import RegisterWidget from "./RegisterWidget";
import CodeSnippet from "./CodeSnippet";


const header = `
<script>
    const qString = new URLSearchParams(window.location.search);
    var tambid;
    var siteid;
    const tmbd = qString.get('tambid');
    const sitd = qString.get('siteId');

    if (tmbd) {
        tambid = tmbd;
        localStorage.tambid = tmbd;
    } else {
        tambid = 1;
    }

    if (sitd) {
        siteid = sitd;
        localStorage.siteId = sitd;
    } else {
        siteid = 'null';
    }

</script>
`;

const headerLM = `
<script>
  const qString = new URLSearchParams(window.location.search);
  var mbid;
  var ccid;
  var siteid;
  const mbd = qString.get('mbid');
  const ccd = qString.get('ccid');
  const sitd = qString.get('siteId');
  if (mbd) {
      mbid = mbd;
      localStorage.mbid = mbd;
  } else {
      mbid = 6;
  }
  if (ccd) {
      ccid = ccd;
      localStorage.ccid = ccd;
  } else {
      ccid = 'null';
  }

  if (sitd) {
      siteid = sitd;
      localStorage.siteId = sitd;
  } else {
      siteid = 'null';
  }
</script>
`;

const prepareCommonHeader = (org, type) => {
  if (org === 'LM') {
    return (type=='lead-capture' ?headerLM : header).replace(/tambid/g, 'mbid').replace(/tmbd/g, 'mbd');
  }
  if (org === 'TA') {
    return header;
  }
  if (org === 'SA') {
    return header.replace(/tambid/g, 'sambid').replace(/tmbd/g, 'smbd');
  }
}

const doesExist = (data, itm) => {
  if (!_.has(data, itm)) {
    return false;
  }
  const i = _.get(data, itm);
  return i && i !== '' && i !== 'false'
};

const returnParams = (data, obj) => {
  let res = '';
  _.forEach(_.keys(obj), key => {
    if (doesExist(data, obj[key])) res += `&${key}={{${obj[key]}}}`
  });
  return res;
};

const generateVendorLink = (type, everWebinar, org, url_params) => {
  // const returnParams = obj => Object.keys(obj).map(key => `&${key}={{${obj[key]}}}`).join('&');
  // determine base url
  let base_url = '';
  let parameters = '';
  const iid = org !== 'LeadMark' ? returnParams(url_params, { iid: 'iterable_project' }) : '';
  if (type === 'live-webinar' || (type === 'dynamic-webinar' && everWebinar)) {
    base_url = 'https://api.webinarlogins.com/gtwWebinarRedirect?';
    if (org === 'LM') {
      parameters += 'mbid=${mbid}';
      parameters += `${returnParams(url_params, { ccid: 'client_campaign_id', sambid: 'sambid', tambid: 'tambid' })}`;
    }
    if (org === 'TA') {
      parameters += 'tambid=${tambid}';
    }
    if (org === 'SA') {
      parameters += 'sambid=${sambid}';
      //        parameters += iid;
    }
    parameters += `&client_id={{client_id}}${returnParams(url_params, { kywd: 'kywd' })}`;
    parameters += doesExist(url_params, 'id_method') ? `&${url_params.id_method}` : '';
    parameters += `&redirect=${encodeURIComponent(url_params.destination_url)}`;
  } else if (type === 'odw-webinar' || (type === 'dynamic-webinar' && !everWebinar)) {
    base_url = 'https://api.webinarlogins.com/odwWebinarRedirect?';
    if (org === 'LM') {
      parameters += 'mbid=${mbid}';
      parameters += `${returnParams(url_params, { ccid: 'client_campaign_id', sambid: 'sambid', tambid: 'tambid' })}`;
    }
    if (org === 'TA') {
      parameters += 'tambid=${tambid}';
    }
    if (org === 'SA') {
      parameters += 'sambid=${sambid}';
      parameters += iid;
    }
    parameters += `&redirect=${encodeURIComponent(url_params.destination_url)}`;
    parameters += `&webinar_id={{webinar_id}}&schedule=${encodeURIComponent(url_params.schedule)}`;
    parameters += `${doesExist(url_params, 'id_method') ? `&{{id_method}}` : ''}`;

  } else if (type === 'lead-capture') {
    if (org === 'LM') {
      base_url = 'https://api.leadmark.com/leads?';
      parameters += 'mbid=${mbid}';
      parameters += '&ccid=${ccid}';
    }
    if (org === 'TA') {
      base_url = 'https://api.tradersagency.com/taLeads?';
      parameters += 'tambid=${tambid}';
    }
    if (org === 'SA') {
      base_url = 'https://api.salesagency.com/leadSalesAgency?';
      parameters += 'sambid=${sambid}';
    }
    // parameters += `&redirect=${encodeURIComponent(url_params.destination_url)}`;
    parameters += '&siteId=${siteid}';
    if (org === 'SA') {
      parameters += iid;
    }
  }


  let link;
  try {
    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    link = `${base_url}${_.template(parameters)(url_params)}`
  } catch (e) {
    console.log(e);
    link = `${base_url}${parameters}`
  }

  return link;
}

const webinarDateAssetHeader =
  `
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment-with-locales.min.js" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.28/moment-timezone-with-data.min.js" crossorigin="anonymous"></script>
`

const WidgetCodes = (props) => {
  const { fields, styles, client, everWebinar } = props;
  const { pattern, url_params, type } = props.widget;

  if (!client) { return null; }

  let org = '';
  if (process.env.ORGANIZATION === 'Traders Agency') {
    org = 'TA';
  }
  if (process.env.ORGANIZATION === 'Traders Agency' && (client.client_name !== 'Traders Agency' && client.client_name !== 'NOFT Traders')) {
    org = 'SA';
  }
  if (process.env.ORGANIZATION === 'LeadMark') {
    org = 'LM';
  }

  const mainHeader = prepareCommonHeader(org, type);

  let widget;
  switch (type) {
    case 'dynamic-webinar':
      if (everWebinar) {
        widget = <WebinarDateAsset useFetchUrl={false} pattern={pattern} />
      } else {
        widget = <WebinarDateAsset useFetchUrl={true} linkData={{ kywd: url_params.kywd, client_id: client.client_id }} pattern={pattern} />
      }
      break;
    case 'lead-capture':
      widget = <RegisterWidget formStyles={styles} fields={fields} redirectUrl={generateVendorLink(type, false, org, url_params)} type="lead-capture" destinationUrl={url_params.destination_url}/>
      break;
    case 'live-webinar':
      widget = <RegisterWidget formStyles={styles} fields={fields} redirectUrl={generateVendorLink(type, false, org, url_params)} type="webinar" />
      break;
    case 'odw-webinar':
      widget = <RegisterWidget formStyles={styles} fields={fields} redirectUrl={generateVendorLink(type, true, org, url_params)} type="webinar" />
      break;
  }
  return <>
    <Grid item xs={4}>
      <p>Place this block of code between the opening and closing head tags of the landing page ({'<head></head>'}).
      If you are using a landing page builder such as LeadPages, this code should go in the head section where analytics tracking code would be placed.
      </p>
      <h4 className="code_header">Page header</h4>
      <CodeSnippet code={mainHeader + (type == 'dynamic-webinar' ? webinarDateAssetHeader : '')} />
    </Grid>
    {widget}
  </>
}

export default WidgetCodes;
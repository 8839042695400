import React from "react";
import _ from "lodash"

import qString from "../../../scripts/qString";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { checkAuth } from "../../helpers/auth";
import FormDesigner from "../../components/LandingPageWidgets/FormDesigner";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            widgetid: _.get(qString, "?id", ""),
        }
    }

    componentDidMount() {
        checkAuth('media-planner');
    }

    render() {
        return (
            <Layout style={{width:'calc(100vw - 20px)'}}>
                <SEO title="Welcome" />
                <h3 className="widgets_header">Form & Widget Builder</h3>
                <FormDesigner widgetid={this.state.widgetid} />
            </Layout>
        );
    }
}

export default Dashboard;

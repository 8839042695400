import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";


class IterableProject extends Component {
  constructor(props) {
    super(props)
    this.state = { projects: [] }
  }

  async componentDidMount() {
    const projects = await this.getIterableProjects();
    this.setState({ projects: projects || [] });
  }

  async getIterableProjects() {
    try {
      const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/iterableProjects")
      const projects = [
        ..._.map(_.get(data, 'iterable_api', []), (project, k) => ({ value: project['client_id'], label: project['client_name'] })),
        { value: false, label: 'No Project' }
      ];
      return projects;
    } catch (error) {
      console.log("Error occurred!")
    }
  }

  render() {
    // const { link_details, updateFormDetails } = this.props;
    const { projects } = this.state;

    return projects.length ? <Grid item xs={4}>
      <InputLabel>Iterable Project</InputLabel>
      <Select
        fullWidth
        margin="dense"
        variant="outlined"
        value={this.props.value || ''}
        onChange={(e) => this.props.onChange(e.target.value, 'iterable_project')}>
        {projects.map(project => (
          <MenuItem value={project.value}
            key={'iterable_project-' + project.value}>{project.label}</MenuItem>
        ))}
      </Select>
    </Grid> : null

  }
};


class ClientCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientCampaigns: [],
    };
  }
  async componentDidMount() {
    const clientCampaigns = await this.getClientCampaigns(this.props.client);
    this.setState({ clientCampaigns });
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.client != this.props.client) {
      const clientCampaigns = await this.getClientCampaigns(this.props.client);
      this.setState({ clientCampaigns });
    }
  }

  async getClientCampaigns(clientId) {
    try {
      const { data } = await axios.get(process.env.SERVER_ENDPOINT + "/clientCampaigns?id=" + clientId);
      return data.client_campaigns;
    } catch (error) {
      return [];
    }
  }
  render() {
    return this.props.client ? <Grid item xs={4}>
      <InputLabel>Client Campaign</InputLabel>
      <Select
        fullWidth
        margin="dense"
        variant="outlined"
        value={this.props.value || ''}
        onChange={(e) => this.props.onChange(e.target.value, 'client_campaign_id')}>
        {this.state.clientCampaigns.filter(c => c.client_id == this.props.client).map(c => (
          <MenuItem value={c.client_campaign_id}
            key={'client-campaign-' + c.client_campaign_id}>{c.client_campaign_id} - {c.client_name}</MenuItem>
        ))}
      </Select>
    </Grid> : null;
  }
}

class IDMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      methods: [
        {
          value: "contact_id=<Contact ID Goes Here>",
          label: "Contact ID"
        },
        {
          value: "email=<Email Goes Here>&fName=<First Name Goes Here>&lName=<Last Name Goes Here>",
          label: "Name & Email"
        }
      ],
    };
  }

  render() {
    return <Grid item xs={4}>
      <InputLabel>Lead Identification Method</InputLabel>
      <Select
        fullWidth
        margin="dense"
        variant="outlined"
        value={this.props.value || ''}
        onChange={(e) => this.props.onChange(e.target.value, 'id_method')}>
        >
      {this.state.methods.map(method => (
          <MenuItem value={method.value}
            key={'id-method-' + method.value}>{method.label}</MenuItem>
        ))}
      </Select>
    </Grid>
  }
}


class Webinar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
    };
  }
  async componentDidMount() {
    const webinars = await this.getWebinars(this.props);
    this.setState({
      webinars: webinars
    });
  }
  async componentWillReceiveProps(props) {
    if (!this.props.client_id || props.client_id !== this.props.client_id) {
      const webinars = await this.getWebinars(props);
      this.setState({
        webinars: webinars
      });
    }
  }

  async getWebinars(props) {
    try {
      const { data } = await axios.get(`${process.env.SERVER_ENDPOINT}/webinars/byClientId/${props.client_id}`);
      return data.webinars;
    } catch (error) {
      return [];
    }
  }
  render() {
    return <Grid item xs={4}>
      <InputLabel>ODW Webinar title</InputLabel>
      <Select
        fullWidth
        margin="dense"
        variant="outlined"
        value={this.props.value || ''}
        onChange={(e) => this.props.onChange(e.target.value, 'webinar_id')}>
        {this.state.webinars.map(webinar => (
          <MenuItem value={webinar.webinar_id}
            key={'odw-webinar-' + webinar.webinar_id}>{webinar.name}</MenuItem>
        ))}
      </Select>
    </Grid>
  }
}


class Schedule extends Component {
  state = {
    webinar: []
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const webinar = await this.getWebinar(this.props);
    this.setState({
      webinar: webinar
    });
  }

  async componentWillReceiveProps(props) {
    if (!this.props.webinar_id || props.webinar_id !== this.props.webinar_id) {
      const webinar = await this.getWebinar(props);
      this.setState({
        webinar: webinar
      });
    }
  }

  async getWebinar(props) {
    try {
      const { data } = await axios.get(`${process.env.SERVER_ENDPOINT}/webinars/byId/${props.webinar_id}/${props.client_id}`);
      return data.webinar;
    } catch (error) {
      return [];
    }
  }
  render() {
    if (this.state.webinar && this.state.webinar.schedules && this.props.webinar_id) {
      return <Grid item xs={4}>
        <InputLabel>ODW Webinar Schedule</InputLabel>
        <Select
          fullWidth
          margin="dense"
          variant="outlined"
          value={this.props.value.schedule ? this.props.value.schedule : ''}
          onChange={(e) => this.props.onChange(e.target.value, 'schedule')}>
          {this.state.webinar.schedules.map(schedule =>
            <MenuItem value={schedule.schedule}
              key={'odw-webinar-schedule_' + schedule.schedule}>{schedule.comment}</MenuItem>
          )}
        </Select>
      </Grid>

    } else {
      return null
    }
  }
}

const LocalTextField = (props) =>
  <Grid item xs={4}>
    {props.tooltip ?
      <Tooltip title={props.tooltip}><InputLabel>{props.label}</InputLabel></Tooltip> :
      <InputLabel>{props.label}</InputLabel>}
    <TextField required fullWidth margin="dense" variant="outlined"
      value={props.value || ''}
      onChange={(e) => props.onChange(e.target.value, props.field)}
    />
  </Grid>

const fields = {
  'iterable_project': (value, onChange, options) =>
    <IterableProject value={value} onChange={onChange} ></IterableProject>,
  'client_campaign_id': (value, onChange, options) =>
    <ClientCampaign value={value} onChange={onChange} client={options.client}></ClientCampaign>,
  'id_method': (value, onChange, options) =>
    <IDMethod value={value} onChange={onChange} ></IDMethod>,
  'kywd': (value, onChange, options) =>
    <LocalTextField value={value} onChange={onChange} field="kywd" label="Keyword"></LocalTextField>,
  'destination_url': (value, onChange, options) =>
    <LocalTextField 
    value={value} 
    onChange={onChange} 
    field="destination_url" 
    label={options.type == 'odw-webinar' || options.type == 'lead-capture' ? 'Thank You Page URL' : 'Alternate URL*'}
    tooltip={options.type == 'odw-webinar' ? "The user will only be redirected to this URL in the case of an error or where there are no on-demand webinars scheduled within the next 15 minutes.": false}
  ></LocalTextField>,
  'webinar_id': (value, onChange, options, allParams) =>
    <Webinar value={value} client_id={allParams.client_id} onChange={onChange}></Webinar>,
  'schedule': (value, onChange, options, allParams) =>
    <Schedule client_id={allParams.client_id} webinar_id={allParams.webinar_id} value={{ schedule: value, webinar_id: allParams.webinar_id }} onChange={onChange}></Schedule>,
  'other_mbid': (value, onChange, options, allParams) =>
    <LocalTextField value={allParams[options.external ? 'sambid' : 'tambid']} onChange={onChange} field={options.external ? 'sambid' : 'tambid'} label={options.external ? 'SA Media Buy ID' : 'TA  Media Buy ID'}></LocalTextField>
}

const params = {
  'dynamic-webinar': {
    'SA': ['kywd'],
    'TA': ['kywd'],
    'LM': ['kywd']
  },
  'lead-capture': {
    'SA': ['iterable_project','destination_url'],
    'TA': ['destination_url'],
    'LM': ['client_campaign_id','destination_url']
  },
  'live-webinar': {
    'SA': ['id_method', 'kywd', 'destination_url', 'iterable_project'],
    'TA': ['id_method', 'kywd', 'destination_url'],
    'LM': ['id_method', 'kywd', 'destination_url', 'client_campaign_id', 'other_mbid']
  },
  'odw-webinar': {
    'SA': ['webinar_id', 'schedule', 'destination_url', 'iterable_project'],
    'TA': ['webinar_id', 'schedule', 'destination_url'],
    'LM': ['webinar_id', 'schedule', 'destination_url', 'client_campaign_id', 'other_mbid']
  }
}

const UrlParams = (props) => {
  const { url_params, onChange, client, type } = props;
  let externalClient = {};

  if (!client) return null;

  let org = '';
  if (process.env.ORGANIZATION === 'Traders Agency') {
    org = 'TA';
  }
  if (process.env.ORGANIZATION === 'Traders Agency' && (client.client_name !== 'Traders Agency' && client.client_name !== 'NOFT Traders')) {
    org = 'SA';
  }
  if (process.env.ORGANIZATION === 'LeadMark') {
    org = 'LM';
    externalClient = { external: (client.client_name !== 'Traders Agency' && client.client_name !== 'NOFT Traders') };
  }
  const options = { client: client.client_id, ...externalClient, type };

  return params[type][org].map(f => <React.Fragment key={f}>
    {fields[f](url_params[f], onChange, options, url_params)}
  </React.Fragment>
  )

}

export default UrlParams;
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";

export default class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: props.initialColor
        };
    }

    componentDidUpdate(prev) {
        if (prev.initialColor !== this.props.initialColor) {
            this.setState({ color: this.props.initialColor });
        }
    }

    render() {
        return <>
            <InputLabel className="mt-2">{this.props.label}</InputLabel>
            <Select
                fullWidth
                margin="dense"
                variant="outlined"
                value={this.state.color || ''} onChange={e => this.selectColor(e.target.value)}>
                {this.props.colors.map(color => {
                    return this.renderColor(color)
                })}
            </Select>
        </>
    }

    renderColorValue(color) {
        if (this.isTransparent(color)) {
            return (<div>transparent</div>);
        }
        const style = this.prepareStyle(color, '95%');
        return (<div style={style} />);
    }

    renderColor(color) {
        if (this.isTransparent(color)) {
            return (
                <MenuItem key="transparent" value={color}>
                    <ListItemText>transparent</ListItemText>
                </MenuItem>
            );
        }
        const style = this.prepareStyle(color, '100%');
        return (
            <MenuItem key={color} value={color}>
                <ListItemText>
                    <div style={style} />
                </ListItemText>
            </MenuItem>
        );
    }

    selectColor(color) {
        this.setState({
            ...this.state,
            color: color
        }, () => {
            this.props.onColorChange(color);
        });
    }

    prepareStyle(color, width) {
        return {
            backgroundColor: color,
            padding: '10px',
            width: width,
            boxSizing: 'border-box',
            border: '1px rgba(0,0,0,0.3) solid',
            borderRadius: 3
        };
    }

    isTransparent(color) {
        return color === 'transparent';
    }
}
import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ColorPicker from "./ColorPicker";

const predefinedWidths = [
    {
        value: 1,
        desc: 'full width'
    },
    {
        value: 0.75,
        desc: '3/4 width'
    },
    {
        value: 0.666666,
        desc: '2/3 width'
    },
    {
        value: 0.5,
        desc: 'half width'
    },
    {
        value: 0.333333,
        desc: '1/3 width'
    },
    {
        value: 0.25,
        desc: '1/4 width'
    }
];

const submitButtonStyles = ['contained', 'outline'];

const colors = [
    "#000000", "#ffffff", "#333333", "#555555", "#777777",
    "#aaaaaa", "#cccccc", "#f44336", "#e91e63", "#9c27b0", "#673ab7",
    "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#607d8b"
];

const corners = ['rounded', 'flat'];

export default class FormStyleEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                ...this.initState(),
                ...this.props.value,
            },
            open: Object.keys(this.props.value).length > 0,
            reset: Object.keys(this.props.value).length == 0,
        }
        this.getStyles = this.getStyles.bind(this);
    }
    initState() {
        return ({
            submitButtonText: 'Submit',
            submitButtonColor: '#3f51b5',
            submitButtonStyle: submitButtonStyles[0],
            submitButtonTextColor: '#ffffff',
            submitButtonWidth: predefinedWidths[0],
            submitButtonCorners: corners[0],
            formWidth: predefinedWidths[0],
            formCorners: corners[0],
            formContainerColor: 'transparent',
            inputCorners: corners[0],
            displayLabels: true,
            inputOutlineColor: '#333333'
        });
    }

    componentDidMount() {
        this.props.onRender({ ...this.state.style });
    }
    componentDidUpdate(props) {
        if (this.props.value !== props.value) {
            const reset = Object.keys(this.props.value).length === 0;
            this.setState({ reset, style: reset ? this.initState() : this.props.value })
        }
    }

    getStyles() {
        this.setState({ style: this.initState(), open: true, reset: false }, () => this.props.onStyleChanged({ ...this.state.style }));
    }

render() {
    const style = this.state.style;
    return (
        <Grid container spacing={3} className="widget_inner_form">
            <Grid item xs={12}>
                <h3 className={!this.state.open ? 'closed' : ''}>
                    {this.state.open ?
                        <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ open: !this.state.open })} /> :
                        <ChevronRightIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ open: !this.state.open })} />
                    }
                        Form Style
                        <button
                        style={{ marginLeft: 'auto' }}
                        onClick={() => this.getStyles()}
                        className="btn white">Get default styles
                        </button>
                </h3>
            </Grid>
            {this.state.open && !this.state.reset && <>
                <Grid item xs={3}>
                    <InputLabel>Form width</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={style.formWidth.value || ''}
                        onChange={(e) => this.formWidthChanged(e.target.value)}>
                        {predefinedWidths.map(width => (
                            <MenuItem value={width.value}
                                key={'form-width-' + width.value}>{width.desc}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>

                    <InputLabel>Form container corners</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={style.formCorners}
                        onChange={(e) => this.formCornersChanged(e.target.value)}>
                        {corners.map(corners => (
                            <MenuItem value={corners}
                                key={'form-corners-' + corners}>{corners}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>

                    <InputLabel>Input corners</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={style.inputCorners}
                        onChange={(e) => this.inputCornersChanged(e.target.value)}>
                        {corners.map(corners => (
                            <MenuItem value={corners}
                                key={'form-input-corners-' + corners}>{corners}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={style.displayLabels}
                                onChange={(e) => this.displayInputLabelsChanged(e.target.checked)} />
                        }
                        label="Display labels for input fields"
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel>Submit button text</InputLabel>
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={style.submitButtonText}
                        onChange={(e) => this.submitButtonTextChanged(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>

                    <InputLabel>Submit button style</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        onChange={(e) => this.submitButtonStyleChanged(e.target.value)}
                        value={style.submitButtonStyle}>
                        {submitButtonStyles.map(style => (
                            <MenuItem value={style}
                                key={'form-submit-btn-style' + style}>{style}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>

                    <InputLabel>Submit button width</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        onChange={(e) => this.submitButtonWidthChanged(e.target.value)}
                        value={style.submitButtonWidth.value || ''}>
                        {predefinedWidths.map(width => (
                            <MenuItem value={width.value}
                                key={'form-submit-btn-width' + width.value}>{width.desc}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>

                    <InputLabel>Submit button corners</InputLabel>
                    <Select
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={style.submitButtonCorners}
                        onChange={(e) => this.submitButtonCornersChanged(e.target.value)}>
                        {corners.map(corners => (
                            <MenuItem value={corners}
                                key={'form-submit-btn-corners-' + corners}>{corners}</MenuItem>
                        ))}
                    </Select>

                </Grid>
                <Grid item xs={3}>
                    <ColorPicker label="Form background" initialColor={style.formContainerColor}
                        colors={["transparent"].concat(colors)}
                        onColorChange={(color) => this.formContainerColorChanged(color)} />
                </Grid>
                <Grid item xs={3}>
                    <ColorPicker label="Submit button color" initialColor={style.submitButtonColor}
                        colors={colors}
                        onColorChange={(color) => this.submitButtonColorChanged(color)} />
                </Grid>
                <Grid item xs={3}>
                    <ColorPicker label="Submit button text color" initialColor={style.submitButtonTextColor}
                        colors={colors}
                        onColorChange={(color) => this.submitButtonTextColorChanged(color)} />
                </Grid>
                <Grid item xs={3}>
                    <ColorPicker label="Input outline color" initialColor={style.inputOutlineColor}
                        colors={colors}
                        onColorChange={(color) => this.inputOutlineColorChanged(color)} />
                </Grid>
            </>}
        </Grid>
    );
}

submitButtonTextChanged(text) {
    console.log(text);
    this.setState({
        style: {
            ...this.state.style,
            submitButtonText: text
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

submitButtonColorChanged(color) {
    this.setState({
        style: {
            ...this.state.style,
            submitButtonColor: color
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

submitButtonStyleChanged(style) {
    this.setState({
        style: {
            ...this.state.style,
            submitButtonStyle: style
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

submitButtonTextColorChanged(textColor) {
    this.setState({
        style: {
            ...this.state.style,
            submitButtonTextColor: textColor
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

submitButtonWidthChanged(widthValue) {
    this.setState({
        style: {
            ...this.state.style,
            submitButtonWidth: predefinedWidths.find(w => w.value === widthValue)
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

submitButtonCornersChanged(corners) {
    this.setState({
        style: {
            ...this.state.style,
            submitButtonCorners: corners
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

inputOutlineColorChanged(color) {
    this.setState({
        style: {
            ...this.state.style,
            inputOutlineColor: color
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

formWidthChanged(widthValue) {
    this.setState({
        style: {
            ...this.state.style,
            formWidth: predefinedWidths.find(w => w.value === widthValue)
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

formCornersChanged(corners) {
    this.setState({
        style: {
            ...this.state.style,
            formCorners: corners
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

formContainerColorChanged(color) {
    this.setState({
        style: {
            ...this.state.style,
            formContainerColor: color
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

inputCornersChanged(corners) {
    this.setState({
        style: {
            ...this.state.style,
            inputCorners: corners
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}

displayInputLabelsChanged(displayLabels) {
    this.setState({
        style: {
            ...this.state.style,
            displayLabels: displayLabels
        }
    }, () => this.props.onStyleChanged({ ...this.state.style }));
}
}